.h1 {
  font-size: large;
  font-weight: 100;
  font-style: italic;
}
.maindiv {
  margin-left: 5%;
  margin-right: 5%;
  /* padding-left: 10%;
  padding-right: 10%; */
  padding-top: 10%;
  justify-content: center;
  background-color: #ffffff;
}
.p {
  font-family: "Gill Sans", "Gill Sans MT", "Calibri", "Trebuchet MS",
    sans-serif;
  font-size: x-large;
}

hr.solid {
  border-top: 3px solid #bbb;
  width: 125%;
  margin-bottom: 17px;
}
h1 {
  color: #398db2;
}
.submain {
  margin-bottom: 50px;
}
.main_heading {
  color: #0033ffbf;
  font-weight: bold;
  font-size: 30px;
}
.main-heading-pri {
  color: #0452c5;
  font-weight: bold;
  font-size: 30px;
}
.main-sub-heading {
  color: #043e95;
  font-weight: bold;
  font-size: 30px;
}

h3 {
  color: #043e95;
  font-weight: bold;
  font-size: 24px;
}
.sub-sub-heading {
  color: #333333;
  font-weight: bold;
  font-size: 24px;
}
.para {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 1%;
  margin-bottom: 2%;
  text-align: justify;
  text-justify: inter-word;
}
.line {
  padding-right: 257px;
}
li {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}
